import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const RichTextEditor = ({ value, name, onChange }) => {
  const handleChange = (content) => {
    onChange({ target: { name, value: content } });
  };

  return (
    <ReactQuill
      value={value}
      onChange={handleChange}
      theme="snow"
      className="mb-4"
      style={{ minHeight: "150px" }}
    />
  );
};

export default RichTextEditor;
